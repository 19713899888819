<template>
  <section class="content-container">
    <SectionTabs :tabs="tab_component"/>
    <router-view/>
  </section>
</template>

<!-- <template>
  <div class="culture">
    <div class="culture-sidebar">
      <SideBar />
    </div>
    <div class="culture__container">
       <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
         <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
         <img src="@/assets/img/forma_2.svg" alt="" class="shape-tr" />
      <div class="admin__body">
        <div class="culture__body-top">
          <SectionTitle :title="title" subtitle="" />
          <ProfileNav />
        </div>
        <div class="tabs" style="padding-left: 40px;">
          <SectionTabs :tabs="tab_component" />
        </div>
        <router-view />
        <footerSec></footerSec>
      </div>
    </div>
  </div>
</template> -->

<script>
import SectionTabs from "@/components/Section/SectionTabs.vue";
/* import SideBar from "@/components/SideBar.vue";
import SectionTitle from "@/components/Section/SectionTitle.vue";
import ProfileNav from "@/components/Profile/ProfileNav.vue";
import footerSec from "@/components/Section/SectionFooter.vue"; */

export default {
  components: { 
      SectionTabs, 
/*       SideBar, 
      ProfileNav, 
      SectionTitle, 
      footerSec  */
    },
  data() {
    return {
      title: "Videos",
      tab_component: [
        {
          name: "Inicio",
          route: "videos-list",
        },
        {
          name: "Agregar",
          route: "videos-add",
        },
        {
          name: "Categorias",
          route: "videos-category",
        },
      ],
    };
  },
  methods:{
    checkRoute(){
      let tab_edit = {
        name: "Editar",
        route: "videos-edit",
      }
      let tab_add = {
        name: "Agregar",
        route: "videos-add",
      }
      if(this.$route.name == "videos-edit"){
        Object.assign(this.tab_component[1], tab_edit);
      }else{
        Object.assign(this.tab_component[1], tab_add);
      }
    }
  },
  mounted() {
    this.checkRoute()
    setInterval(() => {
      this.checkIfUserIsLogged();
    }, 60000);
  },
  updated(){
    this.checkRoute();
  }
};
</script>
